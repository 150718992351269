<template>
  <div>
    <quick-new-user></quick-new-user>
  </div>
</template>

<script>
import {
  default as QuickNewUser
} from "@/components/widgets/QuickNewUser";

export default {
  name: "BulkNewUser",
  computed: {},
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {},
  components: {
    QuickNewUser
  }
};

</script>

<style type="text/css"></style>

