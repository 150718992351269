<template>
  <div style="padding: 10px; font-size: 20px;">
    <b-card header-tag="header" style="min-height: 750px;">
      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
        <button type="button" class="btn btn-secondary" @click="f_showExcelModal()">Excel Ekle</button>
        <button type="button" class="btn btn-secondary pull-right" @click="f_saveAllLines()">Uygun Olanların Hepsini Kaydet</button>
      </b-card-header>
      <div style="width: 100%; overflow-x: auto;">
        <table style="min-width: 100%;">
          <template v-for="(new_user, new_user_ind) in d_newUserList">
            <tr :style="f_calculateRowStyle(new_user_ind)">
              <th>
                Hastane Grubu
              </th>
              <th>
                Hastane Adı
              </th>
              <th>
                Kullanıcı Adı
              </th>
              <th>
                Adı
              </th>
              <th>
                Soyadı
              </th>
              <th>
                Mail
              </th>
              <th>
                İptal
              </th>
              <th>
                Kayıt
              </th>
            </tr>
            <tr :style="f_calculateRowStyle(new_user_ind)" :key="'new_user_line1_' + new_user_ind">
              <td>
                <b-form-select :state="new_user.hospital_group !== '' ? true : false" :plain="true" v-model="new_user.hospital_group" @change="f_changeHospitalGroup(new_user.hospital_group)" required>
                  <option v-for="hospital_groups in d_hospitalGroupList" :value="hospital_groups.value">{{ hospital_groups.label }}</option>
                </b-form-select>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>hastane grubu seçin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-select v-if="new_user.hospital_group && d_hospitalListObj[new_user.hospital_group]" :state="new_user.hospital_id !== '' ? true : false" :plain="true" v-model="new_user.hospital_id" required>
                  <option v-for="hospitals in d_hospitalListObj[new_user.hospital_group]" :value="hospitals.value">{{ hospitals.label }}</option>
                </b-form-select>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>hastane seçin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'username_' + new_user_ind" :key="'username_' + new_user_ind" :formatter="f_toLowerCase(new_user.username, new_user_ind, 'username')" v-model="new_user.username" :state="f_usernameRegexCheck(new_user.username, new_user_ind)"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>hatalı giriş yaptınız</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'first_name_' + new_user_ind" :key="'first_name_' + new_user_ind" v-model="new_user.first_name" :state="new_user.first_name.length > 0 ? true : false"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen isim girin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'last_name_' + new_user_ind" :key="'last_name_' + new_user_ind" v-model="new_user.last_name" :state="new_user.last_name.length > 0 ? true : false"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen soyisim girin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'email_' + new_user_ind" :key="'email_' + new_user_ind" :formatter="f_toLowerCase(new_user.email, new_user_ind, 'email')" v-model="new_user.email" :state="f_emailRegexCheck(new_user.email, new_user_ind)"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen düzgün bir email giriniz</b-form-invalid-feedback>
              </td>
              <td rowspan="3">
                <b-button variant="danger" style="height: 100%; width: 100%;" @click="d_newUserList.splice(new_user_ind, 1);"><i class="fa fa-trash"></i> İptal</b-button>
              </td>
              <td rowspan="3">
                <b-button :disabled="f_checkNewUserLine(new_user_ind)" variant="warning" style="height: 100%; width: 100%;" @click="f_saveThisLine(new_user, new_user_ind)"><i class="fa fa-plus"></i> Kaydet</b-button>
              </td>
            </tr>
            <tr :style="f_calculateRowStyle(new_user_ind)">
              <th>
                Şifre
              </th>
              <th>
                Department
              </th>
              <th>
                Position
              </th>
              <th>
                User-Title
              </th>
              <th>
                ClinicalBranch
              </th>
              <th>
                Şablon Seçimi
              </th>
            </tr>
            <tr :style="f_calculateRowStyle(new_user_ind)" :key="'new_user_line2_' + new_user_ind">
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'password_' + new_user_ind" :key="'password_' + new_user_ind" v-model="new_user.password" :state="new_user.password.length > 0 ? true : false"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen şifre girin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'department_' + new_user_ind" :key="'department_' + new_user_ind" v-model="new_user.department" :state="new_user.department.length > 0 ? true : false"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen departman girin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'position_' + new_user_ind" :key="'position_' + new_user_ind" v-model="new_user.position" :state="new_user.position.length > 0 ? true : false"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen pozisyon girin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'user_title_' + new_user_ind" :key="'user_title_' + new_user_ind" v-model="new_user.user_title" :state="new_user.user_title.length > 0 ? true : false"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen ünvan girin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-input type="text" class="form-control-warning" :id="'clinical_branch_' + new_user_ind" :key="'clinical_branch_' + new_user_ind" v-model="new_user.clinical_branch" :state="new_user.clinical_branch.length > 0 ? true : false"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen klinik branş girin</b-form-invalid-feedback>
              </td>
              <td>
                <b-form-select :state="new_user.schema !== '' ? true : false" :plain="true" v-model="new_user.schema" required>
                  <option value="doctor">Doktor</option>
                  <option value="nurse">Hemşire</option>
                  <option value="pharmacy">Eczacı</option>
                  <option value="secretary">Sekreter</option>
                  <option value="wisdomera">WisdomEra</option>
                  <option value="general">Default Kullanıcı</option>
                </b-form-select>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen şablon seçin</b-form-invalid-feedback>
              </td>
            </tr>
          </template>
          <tr>
            <td colspan="14" style="text-align: center;">
              <b-button variant="warning" block style="width: 100%;" @click="f_addNewLine()"><i class="fa fa-plus"></i> Yeni Satır Ekle</b-button>
            </td>
          </tr>
          <tr v-if="user && user.hospital_group === 'WIS' && user.hospital_id === 'WIS'">
            <td colspan="14" style="text-align: center;">
              <b-button variant="secondary" block style="width: 100%;" @click="f_addNewWisdomLine()"><i class="fa fa-plus"></i> Yeni WisdomEra Çalışan Satırı Ekle</b-button>
            </td>
          </tr>
          <tr v-if="user && user.hospital_group === 'WIS' && user.hospital_id === 'WIS'">
            <td colspan="14" style="text-align: center;">
              <b-button variant="secondary" block style="width: 100%;" @click="f_addNewGeneralLine()"><i class="fa fa-plus"></i> Default Kullanıcı Satırı Ekle</b-button>
            </td>
          </tr>
        </table>
      </div>
    </b-card>
    <modal v-if="d_showExcelModal" @close="d_showExcelModal = false" :width="'750'">
      <h3 slot="header"> Excel Ekleme Ekranı </h3>
      <div slot="body">
        <b-row>
          <b-col cols="3">
            Excel Dökümanı
          </b-col>
          <b-col cols="9">
            <input class="pull-right" id="add_excel" title="Excel ekle" type="file" ref="fileinput" @change="f_addExcel()" accept=".xls,.xlsx">
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_fillDataFromExcel()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showExcelModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as Modal
} from "@/components/widgets/Modal";
import { tr } from '../../../node_modules/flatpickr/dist/l10n/tr';
import { mapGetters } from 'vuex';
import HospitalService from '@/services/hospital';
import UserService from '@/services/users';
import { WisdomRegex } from '@/services/public/functions';
import FileTransferService from '@/services/file_transfer';

export default {
  name: 'QuickNewUser',
  mounted () {},
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  props: {},
  data () {
    return {
      user: {},
      d_showExcelModal: false,
      d_recordedUsernames: [],
      d_recordedEmails: [],
      d_newUserList: [],
      d_emptyNewLine: { 'hospital_group': '', 'hospital_id': '', 'username': '', 'first_name': '', 'last_name': '', 'email': '', 'password': '', 'schema': '', 'department': '', 'position': '', 'user_title': '', 'clinical_branch': '' },
      d_hospitalGroupList: [],
      d_hospitalListObj: {},
      d_excelFile: {}
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getHospitalGroup();
    this.f_getAllUsernameAndEmailList();
  },
  methods: {
    f_getAllUsernameAndEmailList: function () {
      let data = {'list': ['username', 'email']};
      UserService.get_users_info_array(data)
        .then(resp => {
          console.log(resp.data);
          if (resp.data.status === 'success') {
            this.d_recordedUsernames = resp.data.result.username;
            this.d_recordedEmails = resp.data.result.email;
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_calculateRowStyle: function (new_user_ind) {
      if (new_user_ind % 2 === 0) {
        return 'background-color: #c8ced3';
      } else {
        return '';
      }
    },
    f_fillDataFromExcel: function () {
      FileTransferService.convert_quick_new_user_excel(this.d_excelFile)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_newUserList = resp.data.result;
          } else {
            alert('error ', resp.data.message);
          }
          this.d_showExcelModal = false;
        })
    },
    f_showExcelModal: function () {
      this.d_showExcelModal = true;
    },
    f_addExcel: function () {
      if (document.getElementById('add_excel').value) {
        let arr = document.getElementById('add_excel').value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['xlsx', 'xls'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('add_excel').value = '';
          alert('Lütfen xlsx, xls formatını kullanınız');
          return;
        }
        var files = document.getElementById('add_excel').files;
        console.log(files);
        if (!files.length) {
          return;
        }
        var reader = new FileReader();
        reader.onload = (e) => {
          this.d_excelFile.file = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    f_saveThisLine: function (new_user, new_user_ind) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı kayıt ediliyor' } });
      let data = { 'list': [] };
      data.list.push(new_user)
      UserService.quick_new_user(data)
        .then(resp => {
          this.d_newUserList.splice(new_user_ind, 1);
          this.f_getAllUsernameAndEmailList();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_saveAllLines: function (new_user, new_user_ind) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcılar kayıt ediliyor' } });
      let data = { 'list': [] };
      let new_list = [];
      for (let k in this.d_newUserList) {
        if (!this.f_checkNewUserLine(k)) {
          data.list.push(this.d_newUserList[k]);
        } else {
          new_list.push(this.d_newUserList[k]);
        }
      }
      if (data.list.length > 0) {
        UserService.quick_new_user(data)
          .then(resp => {
            this.d_newUserList = JSON.parse(JSON.stringify(new_list));
            this.f_getAllUsernameAndEmailList();
          });
      } else {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_checkNewUserLine: function (new_user_ind) {
      if (this.d_newUserList[new_user_ind].hospital_group === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].hospital_id === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].schema === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].first_name === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].last_name === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].password === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].department === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].position === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].user_title === '') {
        return true;
      }
      if (this.d_newUserList[new_user_ind].clinical_branch === '') {
        return true;
      }
      if (!this.f_emailRegexCheck(this.d_newUserList[new_user_ind].email)) {
        return true;
      }
      if (!this.f_usernameRegexCheck(this.d_newUserList[new_user_ind].username, new_user_ind)) {
        return true;
      }
      return false;
    },
    f_emailRegexCheck: function (email) {
      let regex_status = WisdomRegex.email(email).status;
      if (regex_status) {
        if (this.d_recordedEmails.indexOf(email) !== -1) {
          return false;
        }
      }
      return regex_status;
    },
    f_usernameRegexCheck: function (username, new_user_ind) {
      const regex = /^[\.a-z0-9]*$/ig;

      // var regex = new RegExp("#" + stringToGoIntoTheRegex + "#", "g");
      // at this point, the line above is the same as: var regex = /#abc#/g;
      if (['wisdom', 'admin', 'administrator', 'wisdomera', 'moderator', 'system', 'sys', 'system.admin', 'sys.admin'].indexOf(username) !== -1) {
        return false;
      }

      if (this.d_recordedUsernames.indexOf(username) !== -1) {
        return false;
      }

      for (let k in this.d_newUserList) {
        if (parseInt(k) !== parseInt(new_user_ind) && username === this.d_newUserList[k].username) {
          return false;
        }
      }

      if (username) {
        let regexUsername = regex.exec(username);
        // console.log('regexUsername::::::', regexUsername)
        if (regexUsername && (username === regexUsername[0])) {
          return true;
        }
      }
      return false;
    },
    f_toLowerCase: function (value, new_user_ind, param) {
      this.d_newUserList[new_user_ind][param] = value.toLowerCase()
    },
    f_addNewLine: function () {
      this.d_newUserList.push(JSON.parse(JSON.stringify(this.d_emptyNewLine)));
    },
    f_addNewWisdomLine: function () {
      let push_data = JSON.parse(JSON.stringify(this.d_emptyNewLine));
      push_data.hospital_group = 'WIS';
      push_data.hospital_id = 'WIS';
      push_data.department = 'wisdomera';
      push_data.position = 'clinical_data_managemant_specialist';
      push_data.user_title = 'attendant';
      push_data.clinical_branch = 'internal_medicine';
      push_data.schema = 'wisdomera';
      this.d_newUserList.push(push_data);
    },
    f_addNewGeneralLine: function () {
      let push_data = JSON.parse(JSON.stringify(this.d_emptyNewLine));
      push_data.hospital_group = 'WIS';
      push_data.hospital_id = 'WIS';
      push_data.department = 'general';
      push_data.position = 'general';
      push_data.user_title = 'general';
      push_data.clinical_branch = 'general';
      push_data.schema = 'general';
      this.d_newUserList.push(push_data);
    },
    f_changeHospitalGroup: function (hospital_group) {
      if (!this.d_hospitalListObj[hospital_group]) {
        for (let i in this.d_hospitalGroupList) {
          if (this.d_hospitalGroupList[i].value === hospital_group) {
            this.d_hospitalListObj[hospital_group] = JSON.parse(JSON.stringify(this.d_hospitalGroupList[i].hospital_list));
            this.$forceUpdate();
            break;
          }
        }
      }
    },
    f_getHospitalGroup: function () {
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_hospitalGroupList = JSON.parse(JSON.stringify(resp.data.result));
            for (let i in this.d_hospitalGroupList) {
              this.d_hospitalListObj[this.d_hospitalGroupList[i].value] = JSON.parse(JSON.stringify(this.d_hospitalGroupList[i].hospital_list));
            }
            this.f_changeHospitalGroup();
          }
        });
    }
  },
  watch: {},
  components: {
    Modal
  }
};

</script>

<style type="text/css">
td,
tr,
th {
  padding: 5px;
  font-size: 12px;
}

</style>

